import React, { useCallback, useEffect, useState } from "react";
import { i18n } from "../../localization/i18n";
import { ProgressBar } from "../../components/ProgressBar";
import {
  Container,
  PrintButton,
  CloseButton,
  FindPrinterContainer,
  FindPrinter,
  CrtButtons,
  PrintingActionButton,
  PrinterDisplay,
  TemperatureDisplay,
  Field,
  PrintingCancelButton,
  PreHeatingHeader,
  ExtruderCommandsButton,
} from "./styles";
import {
  RiPauseCircleLine,
  RiCloseCircleLine,
  RiPlayCircleLine,
} from "react-icons/ri";
import { IFileStream, IPrinter } from "../../modules/Orders/dtos";
import ByteSliceTransformer from "../../utils/stream/ByteSliceTransformer";
import DecoderTransformer from "../../utils/stream/DecoderTransformer";
import { ALGO } from "../../utils/constants";
import { EncryptionFactory } from "../../utils/crypto/GenerateEncryptionKey";
import { Loader } from "../Loader";
import { useWebSocket } from "../../hooks/PrinterWebSocketContext";
import LineBreakTransformerForStream from "../../utils/stream/LineBreakTransformerForStream";
import { showToast } from "../CustomToast";
import { usePrinters } from "../../hooks/PrintersContext";
import { MdOutlineKeyboardDoubleArrowUp, MdOutlineKeyboardDoubleArrowDown } from "react-icons/md";
interface ISendToPrinterProps {
  fileStream: IFileStream;
  printingId: string;
  progress: number;
  printer: IPrinter;
  setContentLength: (value: React.SetStateAction<number>) => void;
  setReceived: (value: React.SetStateAction<number>) => void;
  resumePrinting: () => void;
  printStart: () => void;
  printEnd: () => void;
  setPrintHasStarted: (value: boolean) => void;
}
/* Pegar o máximo X da impressora */
const commands = {
  getEndstopStates: "M119",
  changeFilament: "M600",
  pause: "M0", // testando .... o pause é o M25, e o pause M226 é safety, executa o que estiver na fila.
  resume: "M108",
  autoReportTemperature: "M155 S2",
  bedTemperature: "M190",
  extruderTemperature: "M109",
  temperatureStatus: "M105 X0",
  relativePositioning: "G91",
  absolutePositioning: "G90",
  extruderCleanMove: "G0 X150.0", // Movimento com a mesma velocidade
  retractExtruder: "G1 F2400 E-5", // Retract filament 5mm at 40mm/s to prevent stringing
  moveZAxisUp: "G0 F5000 Z20", // Move Z Axis up 20mm to allow filament ooze freely
  swtichOffFan: "M106 S0",
  switchOffExtruder: "M104 S0",
  switchOffBed: "M140 S0",
  stopInconditional: "M2",
  sendExtruderToHomePositioning: "G0 X0 Y400 F5000",
  sendMessage: (message: string) => `M117 ${message}`,
  restoreBedTemperature: (bedPrintingTemperature: number) =>
    `M190 S${bedPrintingTemperature} T0`,
  restoreExtruderTemperature: (extruderPrintingTemperature: number) =>
    `M109 S${extruderPrintingTemperature} T0`,
};
const SendToPrinterViaLan = ({
  progress,
  printingId,
  printer,
  setContentLength,
  setReceived,
  resumePrinting,
  printStart,
  printEnd,
  fileStream,
  setPrintHasStarted,
}: ISendToPrinterProps) => {
  const [wakeLock, setWakeLock] = useState<WakeLockSentinel | null>(null);

  const [printingFase, setPrintingFase] = useState<string | null>(null);

  const [gcodeReader, setGcodeReader] = useState<ReadableStreamDefaultReader<string> | null>(null);

  const [downloadedData, setDownloadedData] = useState(0);
  const [cryptoFileContentLength, setCryptoFileContentLength] = useState(0);
  const [cryptoFileReadableStream, setCryptoFileReadableStream] = useState<ReadableStream<Uint8Array> | null>(null);

  const [preHeatingBedAndExtruder, setPreHeatingBedAndExtruder] = useState(false);
  const [bedTemperatureGoal, setBedTemperatureGoal] = useState(0);
  const [extruderTemperatureGoal, setExtruderTemperatureGoal] = useState(0);
  const [printCanBeConcluded, setPrintCanBeConcluded] = useState(false);

  const [commandSentId, setCommandSentId] = useState<number>(0);

  const { defaultPrinter } = usePrinters()
  const { value, send, setCommandExecutedId, commandExecutedId } = useWebSocket();

  function changePrintStep(step: string) {
    setPrintingFase(step)
  }

  function commandIdGenerator() {
    const generatedId = Math.floor(100000 + Math.random() * 900000)
    setCommandSentId(generatedId);
    return generatedId
  }

  useEffect(() => {
    if ("wakeLock" in navigator) {
      navigator.wakeLock
        .request("screen")
        .then((wl: WakeLockSentinel) => setWakeLock(wl));
    }
    if (fileStream.standard_file) {
      setCryptoFileContentLength(fileStream.standard_file.crypto_length);
      setContentLength(fileStream.standard_file.content_length);
    } else {
      setCryptoFileContentLength(fileStream.custom_file.crypto_length);
      setContentLength(fileStream.custom_file.content_length);
    }

    return () => {
      if (wakeLock !== null) {
        wakeLock.release();
      }
    }
  }, []);

  async function preparePrinting() {
    if (
      process.env.REACT_APP_BASE_URL &&
      fileStream?.id
    ) {
      await getFileStreamOrPrintUncryptedFile();
    } else {
      showToast({ message: "Erro ao buscar o arquivo para impressão", type: "error" })
    }
  }

  useEffect(() => {
    if (printingFase === "prepare") {
      preparePrinting()
    }
  }, [printingFase])

  async function getFileStreamOrPrintUncryptedFile() {
    changePrintStep('getFileStream')
    const requestUrl = `${process.env.REACT_APP_BASE_URL}/printings/${fileStream.id}/print`
    const requestToken = localStorage.getItem("@FixitApp:token")
    try {
      const response = await fetch(requestUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${requestToken}`,
        },
      });
      if (!response.ok || !response.body) {
        throw new Error("Erro ao buscar o arquivo para impressão");
      }
      if (response.status === 200) {
        setCryptoFileReadableStream(response.body);
      } else {
        // Code to print uncrypted file
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function downloadAndStoreFile() {
    if (cryptoFileReadableStream && cryptoFileContentLength) {
      const root = await navigator.storage.getDirectory();
      const dirHandle = await root.getDirectoryHandle("tmpFixitFolder", {
        create: true,
      });
      const fileHandle = await dirHandle.getFileHandle("gcode.fixit", {
        create: true,
      });
      const writable = await fileHandle.createWritable({
        keepExistingData: false,
      });
      const writer = writable.getWriter();
      await cryptoFileReadableStream.pipeTo(
        new WritableStream({
          write(chunk) {
            setDownloadedData((prevState) => prevState + chunk.length);
            writer.write(chunk);
          },
        })
      );
      await writer.close().then(() => changePrintStep('getFileStreamReader'))
    } else {
      throw new Error("Erro ao fazer download de arquivo para impressão");
    }
  }

  useEffect(() => {
    if (cryptoFileReadableStream && cryptoFileContentLength) {
      downloadAndStoreFile()
    }
  }, [cryptoFileReadableStream, cryptoFileContentLength])

  async function getFileAndReturnTextStream() {

    const root = await navigator.storage.getDirectory();
    const dirHandle = await root.getDirectoryHandle("tmpFixitFolder", {
      create: false,
    });
    const fileHandle = await dirHandle.getFileHandle("gcode.fixit", {
      create: false,
    });
    const file = await fileHandle.getFile();
    const stream = file.stream();

    const chunk_size = +(process.env.REACT_APP_CHUNK_SIZE || "1024");
    const counter_size = +(process.env.REACT_APP_COUNTER_SIZE || "16");

    if (!process.env.REACT_APP_FIXIT_FILE_KEY) {
      throw new Error("Cannot find ");
    }

    const textStream = stream
      ?.pipeThrough(
        new TransformStream(
          new ByteSliceTransformer(chunk_size + counter_size)
        )
      )
      ?.pipeThrough(
        new TransformStream(
          new DecoderTransformer(
            ALGO,
            counter_size,
            await new EncryptionFactory().getEncryptionKey(
              ALGO,
              process.env.REACT_APP_FIXIT_FILE_KEY
            ),
            (received: number) => {
              setReceived((prevState) => prevState + received);
            }
          )
        )
      )
      .pipeThrough(new TextDecoderStream());

    const lineBreakStream = textStream.pipeThrough(
      new TransformStream(new LineBreakTransformerForStream())
    );

    setGcodeReader(lineBreakStream.getReader());
  }

  useEffect(() => {
    if (
      cryptoFileContentLength &&
      printingFase === "getFileStreamReader" &&
      downloadedData > 0 &&
      downloadedData === cryptoFileContentLength) {
      (
        async () => await getFileAndReturnTextStream()
      )().then(() => {
        changePrintStep('firstCommand')
      });
    }
  }, [cryptoFileContentLength, downloadedData, printingFase])

  useEffect(() => {
    if (gcodeReader && printingFase === "firstCommand") {
      readAndSendPrinterCommand()
      changePrintStep('print')
    }
  }, [printingFase])


  function getCommands(arrayOfCommands: string[], command: string): string | undefined {
    return arrayOfCommands.find((c) => c.includes(command))
  }


  async function readAndSendPrinterCommand(): Promise<void> {
    const { value: command }: ReadableStreamReadResult<string> =
      await gcodeReader!.read();

    if (command) {

      const commandArray = command.split("\n");

      const bedTemperatureGoal = getCommands(commandArray, commands["bedTemperature"]);
      const extruderTemperatureGoal = getCommands(commandArray, commands["extruderTemperature"]);
      const preHeatingConcluded = getCommands(commandArray, "G1 F3600");
      const printConcluded = getCommands(commandArray, "M84");

      if (bedTemperatureGoal && !preHeatingBedAndExtruder && printingFase === "firstCommand") {
        setPreHeatingBedAndExtruder(true);
        setBedTemperatureGoal(+bedTemperatureGoal.split(' ')[1].slice(1));
      }
      if (extruderTemperatureGoal && !preHeatingBedAndExtruder && printingFase === "firstCommand") {
        setPreHeatingBedAndExtruder(true);
        setExtruderTemperatureGoal(+extruderTemperatureGoal.split(' ')[1].slice(1));
      }
      if (preHeatingConcluded) {
        setPreHeatingBedAndExtruder(false);
      }
      if (printConcluded) {
        setPrintCanBeConcluded(true);
      }

      await send(command, commandIdGenerator());
    }
  }

  useEffect(() => {
    if (gcodeReader && printingFase === "print" && value === "ok") {
      if (commandSentId === commandExecutedId) {
        console.log(commandSentId, " - Sent")
        console.log(commandExecutedId, " - Executed")
        readAndSendPrinterCommand()
      }
    }
  }, [value, printingFase, commandExecutedId])


  return (
    <Container>
      <PrinterDisplay>
        <TemperatureDisplay>
          <Field>
            <strong>Ext: {0}° / </strong>
            {extruderTemperatureGoal}°
          </Field>
          <Field>
            <strong>Bed: {0}° / </strong>
            {bedTemperatureGoal}°
          </Field>
        </TemperatureDisplay>
        <CrtButtons>
          <ExtruderCommandsButton
            disabled={
              progress >= 100 ||
              progress <= 0 ||
              preHeatingBedAndExtruder ||
              printingFase !== 'print'
            }
            onClick={() => {
              send(`G91\nG1 Z0.01\nG90`, commandIdGenerator())
            }}
          >
            <MdOutlineKeyboardDoubleArrowUp size={28} />
          </ExtruderCommandsButton>
          <ExtruderCommandsButton
            disabled={
              progress >= 100 ||
              progress <= 0 ||
              preHeatingBedAndExtruder ||
              printingFase !== 'print'
            }
            onClick={() => {
              send(`G91\nG1 Z-0.01\nG90`, commandIdGenerator())
            }}
          >
            <MdOutlineKeyboardDoubleArrowDown size={28} />
          </ExtruderCommandsButton >
          {printingFase !== "paused" ? (

            <PrintingActionButton
              disabled={
                progress >= 100 ||
                progress <= 0 ||
                preHeatingBedAndExtruder
              }
              onClick={() => {
                changePrintStep('paused')
                send('G1 X3.1 Y20 Z0.28 F5000.0', commandIdGenerator())
              }}
            >
              <RiPauseCircleLine size={28} />
            </PrintingActionButton>
          ) :
            <PrintingActionButton
              disabled={
                progress >= 100 ||
                progress <= 0 ||
                preHeatingBedAndExtruder
              }
              onClick={() => {
                send("G1 X3.1 Y80.0 Z0.28 F1500.0 E10\nG1 X3.4 Y80.0 Z0.28 F5000.0\nG1 X3.4 Y20 Z0.28 F1500.0 E20\nG92 E0\nG1 Z2.0 F3000", commandIdGenerator())
                changePrintStep('print')
              }}
            >
              <RiPlayCircleLine size={28} />
            </PrintingActionButton>
          }

          <PrintingCancelButton
            disabled={
              progress >= 90 ||
              progress <= 0 ||
              preHeatingBedAndExtruder ||
              printingFase !== 'print'
            }
            onClick={() => {
              changePrintStep('stopped')
              send('M112', commandIdGenerator())
            }}
          >
            {<RiCloseCircleLine size={28} />}
          </PrintingCancelButton>
        </CrtButtons>
      </PrinterDisplay>
      {!printingFase ? (
        <PrintButton
          disabled={
            !defaultPrinter?.ipConnected
          }
          type="button"
          onClick={
            () => changePrintStep('prepare')
          }
        >
          {`${i18n.t("orders.sendToPrinter")}`}
        </PrintButton>
      ) : printingFase === 'cancelled' ? (
        <CloseButton type="button" onClick={() => window.location.reload()}>
          {`${i18n.t("topbar.signout")}`}
        </CloseButton>
      ) : preHeatingBedAndExtruder ? (
        <CloseButton type="button" disabled={true}>
          <PreHeatingHeader>
            {`${i18n.t("orders.printChoose.printControl.preHeatingBedAndExtruder")}`}
            <Loader loaderSize={20} />
          </PreHeatingHeader>
        </CloseButton>
      ) : (progress >= 100 || printingFase === "stopped") ? (
        <CloseButton type="button" onClick={() => {
          setCommandExecutedId(0)
          printEnd()
        }}>
          {`${i18n.t("orders.conclude")}`}
        </CloseButton>
      ) : (
        <>
          {printingFase === "prepare" && (
            <>
              <ProgressBar
                text={`Preparando ${(
                  (downloadedData / cryptoFileContentLength) *
                  100
                ).toFixed(0)}%`}
                percentage={(downloadedData / cryptoFileContentLength) * 100}
              />
            </>
          )}
          {printingFase === "print" && (
            <>
              <ProgressBar
                text={`Imprimindo ${progress.toFixed(0)}%`}
                percentage={progress}
              />
              {progress >= 95 && (
                <FindPrinterContainer onClick={resumePrinting}>
                  <FindPrinter>{`${i18n.t(
                    "orders.printComplete"
                  )}`}</FindPrinter>
                </FindPrinterContainer>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};
export default SendToPrinterViaLan;
