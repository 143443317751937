import {
  ILanguageMessages,
  ITranslateCreators,
  ITranslateCustomSolution,
  ITranslateDashboard,
  ITranslateDisabled_user,
  ITranslateDropZone,
  ITranslateErrorMessages,
  ITranslateEvaluation,
  ITranslateFileList,
  ITranslateFinancial,
  ITranslateForm_Errors,
  ITranslateGlobal,
  ITranslateGoBackBtn,
  ITranslateNavMenu,
  ITranslateOptionsBtn,
  ITranslateOrderBox,
  ITranslateOrders,
  ITranslateProfile,
  ITranslateSearchBar,
  ITranslateSidebar,
  ITranslateSolutions,
  ITranslateStandardSolution,
  ITranslateSuccess,
  ITranslateSurgicalPlanning,
  ITranslateTopBar,
  ITranslateUserRegistration,
  ITranslateFixiter,
  ITranslatePrinter,
  ITranslateCourses,
  ITranslateLogin,
  ITranslateTips,
  ITranslateAddress,
  ITranslatePerson,
  ITranslateCoins,
  ITranslateUserProfile,
  ITranslateNoInfo,
  ITranslateLanguage,
  ITranslateBills,
  ITranslateTransactions,
} from "./dtos";

const language: ITranslateLanguage = {
  userLanguage: "fr",
};

const transactions: ITranslateTransactions = {
  header: "Transactions",
};

const noInfo: ITranslateNoInfo = {
  partner: "Vous n'avez pas encore de partenaire enregistré.",
  patient: "Vous n'avez pas encore de patient enregistré.",
  address: "Vous n'avez pas encore d'adresse enregistrée.",
  card: "Vous n'avez pas encore de carte enregistrée.",
};

const bills: ITranslateBills = {
  bills: "Factures",
  completePayment: "Payer le solde de la facture",
  value: "Valeur",
  valueToBePaid: "Montant à payer:",
  card: "Carte",
  ticket: "Ticket",
  pix: "PIX",
  paymentCard: "Carte de paiement",
  didntFindYourCard: "Vous n'avez pas trouvé votre carte ?",
  didntFindYourAddress: "Vous n'avez pas trouvé votre adresse ?",
  billing_address: "Adresse de facturation",
  register: "Enregistrer",
  pay: "Effectuer le paiement",
  linkToAccessTicket: "Lien d'accès au ticket PDF",
  barCode: "Code-barres",
  pixCopyAndPaste: "PIX Copier et Coller",
  changePaymentMethod: "Modifier le mode de paiement",
  requestBill: "Demander une facture",
  latePaymentInfo:
    "*Les paiements effectués après la date d'échéance entraînent une pénalité de 10% et des intérêts de 2%",
  dataSentByMail: "Données envoyées par e-mail",
  error: {
    payment: "Impossible d'effectuer le paiement",
    transaction: "Une erreur s'est produite lors de la transaction",
    mailBill: "Impossible d'effectuer le paiement",
    insufficientFunds: "Solde insuffisant",
  },
  success: {
    payment: "Paiement effectué avec succès",
    mailBill: "Demande de facture effectuée avec succès",
  },
};

const userProfile: ITranslateUserProfile = {
  addresses: "Mes adresses",
  patients: "Mes patients",
  partners: "Mes partenaires",
  paymentInfos: "Cartes",
  users: "Mes utilisateurs",
  closure: "Financier",
  myProfile: "Mon profil",
  addressLabel: "Adresses",
  patientLabel: "Patients",
  userLabel: "Utilisateurs",
  partnerLabel: "Partenaires",
  examsLabel: "Examens",
  closureResume: "Résumé de la facturation",
  ordersMade: "Commandes effectuées",
  ordersPaid: "Commandes payées",
  descriptions: {
    fixPoint: "Nombre de commandes pour lesquelles vous êtes le fournisseur.",
    closure: "Commandes effectuées entre :",
    ordersPaid: "Montant total des commandes que vous avez payées.",
    ordersMade: "Total des commandes effectuées pendant la période de clôture.",
    ordersCourtesy: "Montant total des commandes marquées comme courtoisie.",
    fixPointPopover: "Vérifier quelles commandes",
    closurePopover:
      "Vérifier les commandes effectuées pendant la période en cours",
  },
  my_profile: "Mon profil",
  profile_picture: {
    register: "Enregistrer une photo de profil",
    register_profile_picture: "Enregistrer",
    adjust: "Cliquez pour enregistrer votre photo de profil et l'ajuster.",
    success: "Photo de profil enregistrée avec succès !",
    error:
      "Une erreur s'est produite lors de l'enregistrement de votre photo de profil. Veuillez réessayer ultérieurement.",
  },
  password: {
    success: "Mot de passe modifié avec succès !",
    error: "Un problème est survenu lors du changement de mot de passe.",
  },
  edit_profile_data: "Modifier les données personnelles",
  edit_user_data: "Modifier les données de l'utilisateur",
  edit_licensee_data: "Modifier les données de la licence",
  no_permission: "Vous n'êtes pas autorisé à modifier les données du licencié.",
  yes: "Oui",
  no: "Non",
  profile_data: "Données personnelles",
  user_data: "Données de l'utilisateur",
  licensee_data: "Données de la licence",
  personal_data: {
    document: "Document",
    phone: "Téléphone",
    country: "Pays",
    state: "État",
    city: "Ville",
    success: "Données personnelles mises à jour avec succès !",
    naturalPerson: "Personne physique",
  },
  data_user: {
    email: "E-mail",
    name: "Nom",
    update: "Mettre à jour les données de l'utilisateur",
  },
  data_licensee: {
    fantasy_name: "Nom commercial",
    enterprise_name: "Raison sociale",
    document: "CPF | CNPJ",
    situation: "Situation",
    phone: "Téléphone",
    company_phone: "Téléphone de l'entreprise",
    contract: "Contrat",
    printer: "Imprimante",
    fixpoint: "FixPoint",
    update: "Mettre à jour les données du licencié",
    manager_name: "Nom du responsable de la licence",
    instagram: "Instagram",
    region: "Région",
    printers: "Imprimantes",
  },
  selectedPrinters: "Imprimantes sélectionnées",
  noPrinterSelected: "Aucune imprimante sélectionnée",
  actualClosure: "Facturé en cours",
  previousClosure: "Facturé clôturé",
};

const fixiterOnboarding: ITranslateFixiter = {
  introduction:
    "Tout est prêt pour commencer à offrir le meilleur à vos patients ?",
  proceed: "Continuer",
  initialKit: "Demander un kit initial",
  firstStepHeader:
    "Découvrez un peu plus sur la technologie Fix it dans cette vidéo :",
  secondStepHeader: "Tout sur notre technologie d'impression 3D",
  thirdStepHeader: "Maintenant, apprenons à appliquer le PunhoFix",
  firstDescription:
    "Mais avant de commencer, laissez-nous vous expliquer comment cela fonctionnera, d'accord ?",
  description: "Notre processus de démarrage se compose de deux étapes :",
  stepOne: "Technologie Fix it",
  stepTwo: "Application de la principale orthèse (PunhoFix)",
  stepThree: "Comment utiliser la plateforme",
  startTheOnboard: "COMMENCER",
  startToUseTheApp: "COMMENCER À UTILISER L'APPLICATION",
  sendCertificate: "Envoyer une photo de mon diplôme",
  send: "Envoyer",
  cancel: "Annuler",
  sendDiploma: "Envoyer un diplôme",
  document_success: "Document envoyé avec succès !",
  document_failed:
    "Impossible d'envoyer votre document ! Veuillez contacter le SAF.",
  chooseFile: "Sélectionner un fichier à envoyer (.png, .jpeg, .jpg)",
  selectSolutions: "SÉLECTIONNER DES SOLUTIONS",
  conclusionHeader: "Il ne vous reste plus qu'un peu pour devenir un Fixiter !",
  firstConclusionDescription:
    "Mais félicitations d'avoir terminé les formations sur la plateforme, nous pouvons déjà dire que vous faites partie de Fix it et de la révolution dans le domaine de la santé. Maintenant, nous devons simplement valider vos informations pour vous donner accès.",
  conclusionDescription:
    "Envoyez une photo de votre diplôme sur notre WhatsApp, nous vous répondrons dans un délai de 24 heures pour valider votre accès. Pendant ce temps, ne perdez pas de temps, cliquez sur le bouton ci-dessous pour être redirigé et valider votre utilisateur. Une fois approuvé, vous pourrez passer votre première commande et offrir la meilleure expérience à votre patient !",
  attachments: {
    header: "Pièces jointes",
    portfolio: "Télécharger le portfolio",
    tools: "Lien pour l'achat d'outils et de fournitures",
    startKit: "Passer la commande du kit initial",
    goodPractices:
      "Guide des bonnes pratiques avec l'attelle Fix it, à remettre au patient",
    heatBlower: "Souffleur thermique",
    scissors: "Ciseaux",
    measuringTape: "Mètre ruban",
    velcro: "Velcro",
    tubularMesh: "Filet tubulaire (10 cm de diamètre)",
  },
  stepTwoDescription: `• À l'étape suivante, vous devrez suivre quelques instructions : L'application est très simple, mais vous devrez avoir les outils nécessaires à portée de main. Si vous ne les avez pas, il vous suffit de cliquer sur le lien ci-dessous :`,
  firstConclusionWelcome: "Bienvenue dans l'avenir de la santé !",
  initialKitDescription:
    "• Maintenant, avec toutes les instructions suivies, continuons avec la vidéo d'application de notre orthèse PunhoFix ?",
  attachmentsToolDescription: `• Les liens ci-dessous sont des références pour l'achat. La plupart des fournitures et des outils peuvent être trouvés dans des magasins près de chez vous, ce qui réduit le temps et les frais de livraison.`,
  attachmentsStartKitDescription:
    "• Pour vous entraîner, il vous suffit de demander ci-dessous votre kit initial, qui est entièrement gratuit.",
};

const login: ITranslateLogin = {
  email: "E-mail",
  password: "Mot de passe",
  forgotPassword: "J'ai oublié mon mot de passe",
  login: "Connectez-vous",
  register: "S'inscrire",
  submit: "Entrer",
  dontHaveAccount: "Vous n'avez pas de compte ?",
  recover_password: "Récupération de mot de passe",
  send_code: "Envoyer le code de réinitialisation",
  new_password: "Nouveau mot de passe",
  new_password_confirmation: "Confirmez le nouveau mot de passe",
  change_password: "Modifier le mot de passe",
  password_changed: "Mot de passe modifié avec succès !",
  starting: "Initialisation",
};

const tips: ITranslateTips = {
  dontShowAgain: "Ne plus afficher",
  title: "Nouvelles fonctionnalités",
  create: "Nouvelle astuce",
  edit: "Modifier",
  delete: "Supprimer",
  createButton: "Créer une astuce",
  editButton: "Enregistrer",
  name: "Nom",
  users: "Utilisateurs",
  type: "Type",
  description: "Description",
  success: "Astuce créée avec succès",
  descriptionError:
    "Une erreur s'est produite lors du chargement de la nouveauté",
  error: "Une erreur s'est produite lors de la création de l'astuce",
  page: "Page",
  updateButton: "Mettre à jour",
  updateError: "Une erreur s'est produite lors de la modification de l'astuce",
  changeStateError:
    "Une erreur s'est produite lors de la mise à jour de l'astuce",
  specificUrl: "Paramètre",
  activateTip: "Activer l'astuce",
  cancel: "Annuler",
  confirm: "Confirmer",
  deleteTip: "Supprimer l'astuce",
  deleteError: "Une erreur s'est produite lors de la suppression de l'astuce.",
  globalWarning:
    "Nous avons détecté qu'une astuce globale est déjà activée, souhaitez-vous la désactiver pour activer celle-ci ?",
  pageWarning:
    "Nous avons détecté qu'une astuce pour cette page est déjà activée, souhaitez-vous la désactiver pour activer celle-ci ?",
  deleteWarning:
    "Cette astuce est encore active, êtes-vous sûr de vouloir la supprimer ?",
  new: "Nouveautés",
};

const userRegistration: ITranslateUserRegistration = {
  connector: "et",
  stepOne: "Créer un compte",
  stepTwo: "Données d'inscription",
  stepThree: "Données de l'entreprise",
  stepFour: "Forfaits",
  nextStep: "Suivant",
  prevStep: "Précédent",
  name: "Nom",
  email: "E-mail",
  password: "Mot de passe",
  password_confirmation: "Confirmation du mot de passe",
  fillCodes: "Entrez le code que nous avons envoyé à votre adresse e-mail",
  fillNameAndEmail: "Remplissez votre e-mail et votre mot de passe",
  fillData: "Remplissez vos données",
  country: "Pays",
  cpf: "Numéro d'identification fiscale (CPF)",
  cellphone: "Téléphone portable",
  state: "État",
  city: "Ville",
  terms: "Conditions d'utilisation",
  policy: "Politiques de confidentialité",
  termsOfUse: `J'ai lu et j'accepte les`,
  companyNumber: "Numéro d'identification de l'entreprise (CNPJ)",
  companyData: "Renseignez les données de votre entreprise",
  companyName: "Raison sociale",
  tradingName: "Nom commercial",
  continueAsNaturalPerson: "Continuer en tant que personne physique",
  plans: "Choisissez votre plan",
  choosePlan: "Choisir un plan",
  choosedPlan: "Sélectionné",
  errorChoosePlan: "Veuillez choisir un plan",
  errorAsigneePlan: "Une erreur s'est produite avec le plan",
  errorCreatePerson:
    "Une erreur s'est produite lors de la création de l'utilisateur",
  termsError: "Une erreur s'est produite lors du chargement du document",
  validCode: "Veuillez saisir un code valide.",
  emailInUse: "Adresse e-mail déjà utilisée.",
  codeExpires: "Le code expire le : ",
  sendCode: "Renvoyer le code",
  sendedCode: "Code renvoyé !",
  codeExpired: "Le code a expiré.",
  professional_number: "Numéro d'adhérent",
  expertise: "Spécialité",
  instagram: "Instagram",
  notOperational:
    "Oups ! Fix it n'est pas encore disponible dans votre région.",
  noOperationalDescription:
    "Veuillez contacter notre service clientèle pour savoir comment amener Fix it chez vous.",
  contactUs: "Contactez-nous",
  finishLater: "Choisir plus tard",
  fillManagerData: "Renseignez les données du responsable",
  confirm: "Confirmer",
};

const creators: ITranslateCreators = {
  solutions: "Gérer les solutions",
  make: "Produire",
  addFile: "Ajouter un fichier",
  side: "Côté",
  size: "Taille",
  part: "Partie",
};

const global: ITranslateGlobal = {
  underConstruction: "Bientôt disponible",
  solutions: "Solutions",
  situation: "Situation",
  undo: "Annuler",
  regions: {
    southeast: "Sud-Est",
    south: "Sud",
    north: "Nord",
    northEast: "Nord-Est",
    midwest: "Centre-Ouest",
    international: "International",
  },
  customerSituation: {
    effected: "Vente réalisée",
    implementation: "En cours d'implémentation",
    operational: "En fonctionnement",
    training: "En formation",
    onboarding: "Onboarding",
  },
  licensees: {
    header: "Licenciés",
    create: "Créer un licencié",
    edit: "Modifier un licencié",
    field: {
      document_number: "CNPJ/CPF",
      email: "E-mail",
      phone: "Téléphone",
      instagram: "Instagram",
      is_public: "Public",
      natural_person: "Personne physique",
      legal_entity: "Entreprise",
      cpfMask: "999.999.999-99",
      cnpjMask: "99.999.999/9999-99",
      manager_name: "Responsable",
      is_test: "Licencié de test",
    },
  },
  designers: {
    header: "Designers",
    create: "Créer un designer",
    edit: "Modifier un designer",
  },
  configs: {
    header: "Paramètres généraux",
    createUserTerms: "Nouveaux termes d'utilisation",
    solutionFields: "Domaines des solutions",
    createSolutionField: "Nouveau domaine",
    createSolutionCategory: "Nouvelle catégorie",
    solutionCategories: "Catégories de",
    editSolutionField: "Modifier ",
    editSolutionCategory: "Modifier ",
    docs: {
      header: "Documents - Contrat {{contract}}",
      label:
        "Ces documents peuvent être consultés tout au long de l'application pour une utilisation générale, notamment dans les contrats d'acceptation tels que les conditions d'utilisation et la politique de confidentialité.",
    },
    printers: {
      header: "Imprimantes homologuées",
      edit: "Modifier l'imprimante",
      create: "Créer une imprimante",
      firstStep:
        "Dans cette première étape, vous devez avoir installé le pilote de l'imprimante (Mac ou Windows), puis, avec l'imprimante connectée à un port USB, cliquez sur le bouton ci-dessous.",
      secondStep:
        "Dans la deuxième étape, nous allons effectuer quelques mouvements sur l'imprimante, totalement hors ligne, c'est-à-dire que ce processus fonctionne même sans connexion Internet. Cela testera la communication avec le périphérique.",
      conclusion:
        "Maintenant, tout est prêt pour lancer vos impressions. N'hésitez pas à préchauffer le lit et l'extrudeuse ici ou directement depuis l'imprimante.",
      heatBed: "Préchauffer le lit à 65ºC",
      heatExtruder: "Préchauffer l'extrudeuse à 210ºC",
      configure: "Configurer l'imprimante",
      connect: "Connecter l'imprimante",
      notfound: "Vous ne trouvez pas votre appareil ?",
      manualSearch: "Rechercher ici",
      test: "Démarrer le test",
      baudRateChangeSucess:
        "Changement de vitesse de transmission (Baud Rate) effectué avec succès",
      changeBaudRateTo: "Changer la vitesse de transmission (Baud Rate) à",
    },
    users: {
      header: "Utilisateurs internes",
      edit: "Modifier l'utilisateur",
      create: "Créer un utilisateur",
    },
    tips: {
      header: "Nouvelles fonctionnalités",
    },
  },
  novalue: "Effacer",
  status: {
    label: "Enregistrement actif",
    active: "Actifs",
    deactive: "Inactifs",
    registered: "Enregistrés",
  },
  sides: {
    ESQ: "Gauche",
    DIR: "Droite",
    UNI: "Unique",
  },
  roles: {
    LICENSEE: "Licencié",
    FIXITER: "Fixiter",
    CREATOR: "Designers de produits",
    ADMIN: "Administrateur",
    SAF: "Service client (SAF)",
    EMPLOYEE: "Utilisateur",
  },
  password: {
    password: "Mot de passe actuel",
    password_confirmation: "Confirmez le nouveau mot de passe",
    new_password: "Nouveau mot de passe",
    save_changes: "Enregistrer les modifications",
    change_password: "Changer de mot de passe",
  },
};

const dashboard: ITranslateDashboard = {
  header: "Rapports",
  requestsDay: "Demandes aujourd'hui",
  requestsMonth: "Demandes du mois",
  requestsSevenDays: "Demandes des 7 derniers jours",
  requestsYear: "Demandes en",
  revenueYear: "Revenu en",
  clickSevenDays: "Clics sur le site des 7 derniers jours",
  soon: "PROCHAINEMENT",
  nps: "NPS",
  months: {
    jan: "Jan",
    feb: "Fév",
    mar: "Mar",
    apr: "Avr",
    may: "Mai",
    jun: "Jui",
    jul: "Jui",
    aug: "Aoû",
    sep: "Sep",
    oct: "Oct",
    nov: "Nov",
    dec: "Déc",
  },
  newPatients: "Nouveaux patients",
  bill: "Facture",
  filamentUse: "Consommation de filament",
  filamentInfo: "Moyenne calculée sur la base des commandes standardisées",
  error: "Impossible de charger les informations du tableau de bord",
  caseIncidence: "Incidence des cas",
  mostAskedSolutions: "Solutions les plus demandées",
  patientsInfo: "Patients inscrits pendant la période sélectionnée",
  month: "Mois",
  apply: "Exécuter",
  period: "Période",
};

const solutions: ITranslateSolutions = {
  header: "Solutions Fix it",
  select: "Sélectionner",
  whichSize: "QUELLE TAILLE ?",
  aplicationVideo: "Vidéo d'application",
  categories: {
    header: "Quelle catégorie de solution souhaitez-vous ?",
  },
  solutions: {
    header: "Choisissez une solution",
  },
  download: "Télécharger",
  unique_part: "Unique",
  image_upload_title: "Mettre à jour l'image",
  image_upload_subtitle: "Image d'affichage de la solution",
  save_new_image: "Enregistrer la nouvelle image",
  edit_image: "Modifier l'image de la solution",
  download_file_enable: "Activer",
  download_file_upload_title: "Mettre à jour le téléchargement",
  download_file_upload_subtitle: "Fichier à télécharger pour la solution",
  custom_update_title: "Mise à jour personnalisée",
  custom_update_subtitle: "Données de la solution personnalisée",
  standard_update_title: "Mise à jour standardisée",
  standard_update_subtitle: "Données de la solution standardisée",
  size_patterns: "Modèles de taille",
  is_custom: "Solution personnalisée",
  is_standard: "Solution standardisée",
  is_download: "Solution à télécharger",
  edit: "Modifier la solution",
  name: "Nom de la solution",
  category: "Catégorie",
  field: "Domaine",
  form_type: "Type de formulaire",
  suggested_price: "Prix suggéré",
  side: "Côté",
  number_sides: "Côtés",
  updateButton: "Enregistrer les données",
  number_parts: "Parties",
  part: "Partie",
  number_measures: "Mesures",
  link_guide: "Lien vers le guide des mesures",
};

const success: ITranslateSuccess = {
  header: "Succès client",
};

const orders: ITranslateOrders = {
  financial_status: {
    order_status: "Statut de commande",
    paid: "Payé",
    not_paid: "Non payé",
    courtesy: "Cortesie",
    success: "Statut de commande mis à jour avec succès.",
    error:
      "Une erreur s'est produite lors de la mise à jour du statut de commande.",
  },
  confirmation: {
    service_number: "Numéro de service",
    confirm: "Confirmer les données de la commande",
    patient: "Patient",
    years: "ans",
    solution: "Solution",
    custom: "Personnalisée",
    standard: "Standard",
    profissional: "Professionnel",
    partner: "Partenaire",
    measures: "Mesures",
    bodyPart: "Partie du corps",
    injuryType: "Type de blessure",
    surgeryDate: "Date de chirurgie",
    target: "Ce qui doit être ciblé",
    markAsUrgency: "Marquer comme urgence",
    urgencyDetails:
      "Les commandes marquées comme urgentes seront facturées le double du montant.",
    totalValue: "Montant total",
    justification: "Justification",
    responsabilityConfirmation:
      "Je déclare que les informations fournies sont de ma responsabilité et qu'elles sont toutes correctes.",
    cancel: "Annuler",
    confirmAndFinish: "Confirmer et terminer",
  },
  urgency: {
    requested: "Demande d'urgence pour la commande",
    requestedInfo: "Une demande d'urgence a été faite pour la commande",
    requestedApprove: "voulez-vous l'approuver ?",
    approve: "Approuver",
    refuse: "Refuser",
    popoverRequested: "Urgence demandée",
    popoverApproved: "Urgence approuvée",
    popoverNonJustified: "Urgence non justifiée",
    additionalValueJustification:
      "Les commandes personnalisées auront un supplément de {{value}} {{currency}}, en cas d'urgence, {{doubleValue}} {{currency}}",
  },
  actions: {
    new: "NOUVEAU",
    edit: "MODIFIER",
    save: "ENREGISTRER",
    close: "FERMER",
    discart: "JETER",
    upload: "TÉLÉCHARGER",
  },
  pending_info: {
    text: "Informations en attente",
  },
  patient_section: {
    search: {
      error: "Une erreur s'est produite lors du chargement des patients.",
    },
    empty_title: "Données du patient",
    empty_subtitle: "Âge, poids, téléphone...",
  },
  partner_section: {
    search: {
      error: "Une erreur s'est produite lors du chargement des professionnels.",
    },
    empty_title: "Données du partenaire",
    empty_subtitle: "Téléphone, email...",
    entities: {
      natural_person: "PERSONNE PHYSIQUE",
      legal_entity: "PERSONNE MORALE",
    },
  },
  solution_section: {
    empty_title: "TERMINER ET ENVOYER",
    empty_subtitle: "Décrivez le produit : couleur, mesures...",
    measurement_guide: "Consulter le guide des mesures",
  },
  exam_section: {
    empty_title: "DONNÉES D'ÉVALUATION",
    empty_subtitle: "Professionnel, diagnostic, membre...",
    professional_label: "Données du professionnel responsable",
  },
  planit_section: {
    title_template: "PLANIFICATION ",
    empty_title: "PLANIFICATION",
    empty_subtitle: "Professionnel, blessure, fichiers...",
    doctor_subsection_title: "informations du médecin/chirurgien responsable",
    evaluation_subsection_title: "Évaluation",
    files_subsection_title: "Fichiers",
  },
  order_type: {
    is_patient: "POUR MON PATIENT",
    is_partner: "POUR MON PARTENAIRE",
    is_fixiter: "POUR UN FIXITER",
  },
  create: {
    custom: {
      header: "Commande personnalisée",
      header_template: "Pers. ",
    },
    standard: {
      header: "Commande standard",
      header_template: "Std. ",
    },
    plan: {
      header: "Plan chirurgical",
      header_template: "Plan chir.",
    },
  },
  printChoose: {
    header: "Contrôle des impressions",
    available_info: "{{available}} sur {{quantity}} disponibles",
    standard: "Standard",
    sides: "Côté",
    save: "Enregistrer et préparer l'impression",
    prepare: "Préparer l'impression",
    error: "Oops ! Impression indisponible",
    advice: "En attente de données",
    waitingchoice: "En attente de choix",
    noprinter: "Veuillez sélectionner une imprimante",
    useStandardFileOption: "Conserver {{print_option}}",
    proceed: "Continuer l'impression - {{file_choice}}",
    demand: "Demande d'impression - {{file_choice}}",
    end: "Terminer l'impression - {{file_choice}}",
    endAndSelect: "Terminer et faire un nouveau choix",
    add: "Ajouter un nouveau choix",
    progress: "Suivre la progression",
    waiting: "Impression en cours - {{file_choice}}",
    notStarted: "En attente de FixPoint",
    completed: "Impression terminée - {{file_choice}}",
    printControl: {
      header: "Choisir la pièce",
      selectStream: "Imprimer {{print_option}}",
      completed: "Impression terminée - {{print_option}}",
      tryagain: "Réessayer - {{print_option}}",
      advice: "En attente de choix",
      error: "Impossible de démarrer l'impression",
      empty: "Aucune pièce disponible pour l'impression",
      print: "Configurer l'impression",
      printing: "Impression en cours - {{print_option}}",
      preHeatingBedAndExtruder: "Préchauffage du lit et de l'extrudeuse",
    },
  },
  creator: "Designer",
  licensee: "Client",
  print: "Options d'impression",
  download: "Options de téléchargement",
  view: "Détails de la commande",
  printHistory: "Historique des impressions",
  printManagement: "Gestion des impressions",
  printStarting: "Nous sommes prêts à imprimer,",
  downloadStarting: "Votre téléchargement commence,",
  printingSuccess: "vérifiez les données !",
  clickToDownload: "Pas encore commencé ? Cliquez ici pour télécharger.",
  printingData: "Données d'impression",
  printingTime: "Temps",
  materialCost: "Coût du matériau",
  materialWeight: "Poids",
  custom_production_update_title: "Mise à jour de personnalisation",
  custom_production_update_subtitle: "Informations de production",
  start_development: "Démarrer le développement",
  call_review: "Demander une révision",
  is_cancelled: "Commande annulée",
  commit_review: "Terminer la révision",
  header: "Commandes",
  as_provider: "Demandes FixPoint",
  date: "Date",
  patient: "Patient",
  partner: "Partenaire",
  solution: "Solution",
  type: "Type",
  category: "Catégorie",
  quantity: "Quantité",
  printer: "Imprimante",
  state: "État",
  price: "Total R$",
  total: "Total ({{order_type}})",
  files: "Fichiers",
  selectPrinter: "Sélectionnez une imprimante...",
  sendToPrinter: "Envoyer à l'imprimante",
  conclude: "Terminer",
  didNotFindPrinter: "Vous ne trouvez pas votre imprimante dans la liste ?",
  printComplete: "L'impression est terminée ?",
  didNotFoundNavigator: "Il semble que votre navigateur n'est pas Chrome !",
  chromeDownload1:
    "Pour une meilleure utilisation des systèmes FixIt, nous vous recommandons de l'utiliser !",
  chromeDownload2: "Ou vous pouvez le télécharger ici :",
  clickToProceed: "Cliquez pour continuer",
  patientNotSet: "Non renseigné",
  status: {
    processing: {
      value: "processing",
      optionText: "Non démarré",
    },
    development: {
      value: "development",
      optionText: "En développement",
    },
    review: {
      value: "review",
      optionText: "En cours de révision",
    },
    cancelled: {
      value: "cancelled",
      optionText: "Annulé",
    },
    completed: {
      value: "completed",
      optionText: "Terminé",
    },
    expired: {
      value: "expired",
      optionText: "Expiré",
    },
  },
  fields: {
    status: "État",
  },
  cancelConfirmation: "Êtes-vous sûr de vouloir annuler la commande ?",
  qtd: "QTD",
  order_provider: {
    requester: "Demandeur",
    order_data: "Données de commande",
    company_name: "Nom de l'entreprise :",
    fantasy_name: "Nom de fantaisie :",
    responsible: "Responsable :",
    location: "Localisation :",
  },
  filter: {
    sublicensee: "Sous-licencié",
  },
  planit: {
    submit_file_upload: "Envoyer l'image",
    file_upload: "Chargement d'image",
  },
};

const profile: ITranslateProfile = {
  header: "Mon unité",
  unit: "Unité",
  email: "Email",
  state: "Statut",
  region: "Région",
  managerName: "Nom du gestionnaire",
  phone: "Téléphone",
  instagram: "Instagram",
  editProfile: "Modifier le profil",
  changePassword: "Changer le mot de passe",
  printers: "Imprimantes",
  addPrinter: "Ajouter une imprimante",
};

const sidebar: ITranslateSidebar = {
  reports: "Rapports",
  solutions: "Solutions",
  requests: "Demandes",
  files: "Fichiers",
  success: "Succès",
  designers: "Designers",
  licensees: "Licenciés",
  config: "Configuration",
  signout: "Déconnexion",
  prices: "Prix",
  plans: "Plans",
  onboarding: "Onboarding",
  courses: "Cours",
};

const navMenu: ITranslateNavMenu = {
  reports: "Rapports",
  solutions: "Solutions",
  requests: "Demandes",
  files: "Fichiers",
  success: "Succès",
};

const topbar: ITranslateTopBar = {
  coinFix: {
    start: "Vous avez",
    end: "FixCoin",
  },
  notification: "Youpi, vous avez {{ quantity }} notifications !",
  editProfile: "Modifier le profil",
  signout: "Déconnexion",
  noprinter: "Aucune imprimante",
  fixpoint: "FixPoint",
  change_password: "Changer le mot de passe",
  my_users: "Mes utilisateurs",
  filamentData: "Données du filament",
  addPerson: "Ajouter des informations personnelles",
};

const dropZone: ITranslateDropZone = {
  dragActive: "Cliquez ou faites glisser pour ajouter",
  error: "Fichier non pris en charge",
  success: "Déposez les fichiers ici",
};

const fileList: ITranslateFileList = {
  delete: "Supprimer",
};

const goBackBtn: ITranslateGoBackBtn = {
  back: "Retour",
};

const optionsBtn: ITranslateOptionsBtn = {
  successMessage: "Commande annulée avec succès.",
  evaluation: "Accéder à l'évaluation",
  problem: "J'ai un problème",
  cancel: "Annuler la commande",
  printHistory: "Historique d'impression",
};

const orderBox: ITranslateOrderBox = {
  date: "Date",
  patient: "Patient",
  solution: "Solution",
  category: "Catégorie",
  quantity: "Quantité",
  printer: "Imprimante",
  state: "Statut",
  price: "Total R$",
  files: "Fichiers",
};

const searchBar: ITranslateSearchBar = {
  placeholder: "Rechercher quelque chose...",
};

const surgicalPlanning: ITranslateSurgicalPlanning = {
  personalize: "Planification chirurgicale",
  nullTargetField: "Indiquez ce qui doit être segmenté",
  segmentation: "Segmentation",
  doctorInformation: "Informations du médecin/chirurgien responsable",
  partOfTheBody: "Partie du corps",
  lesionType: "Type de lésion",
  whatShouldBeSegmented: "Ce qui doit être segmenté",
  surgeryDate: "Date de la chirurgie",
};

const standardSolution: ITranslateStandardSolution = {
  buy: "Standard",
  request: "Commander",
  size: "Tailles",
  side: "Côté",
  quantity: "Quantité",
  printingInfo: "Informations d'impression",
  selectPrinter: "Imprimante",
};

const customSolution: ITranslateCustomSolution = {
  buy: "Personnalisée",
  updated_at: "Mis à jour il y a",
  updated_at_suffix: "min.",
  noFilePrice: "En attente des données du formulaire pour le calcul du prix",
  noSelectedPrinter: "Aucune imprimante sélectionnée",
  noStandardFile: "Aucun fichier disponible pour la configuration indiquée",
  totalPrice: "Total :",
  previous: "Précédent",
  next: "Suivant",
  successToast: "Commande réalisée avec succès !",
  errorToast: "Erreur lors de la commande ! Détails :",
  sendOrder: "Envoyer la commande",
  personalize: "Personnaliser",
  selectPrinter: "Sélectionnez une imprimante",
  step: "Étape",
  of: "de",
  patientData: "Informations sur le patient",
  fullName: "Nom complet",
  company_name: "Raison sociale",
  professional_number: "Numéro professionnel",
  cpf: "CPF",
  cnpj: "CNPJ",
  cep: "CEP",
  contact: "Contact",
  contactMask: "(99) 99999-9999",
  cepMask: "99999-999",
  measuresError: "Veuillez ajouter les mesures.",
  nameError: "Entrez le nom complet du patient !",
  professionalNameError: "Saisissez le nom du professionnel.",
  diagnosisError: "Décrivez le diagnostic réalisé.",
  age: "Âge",
  months: "Mois",
  weight: "Poids (kg)",
  height: "Taille (cm)",
  responsibleName: "Nom du responsable",
  responsiblePlaceholder: "Si mineur ou handicapé",
  phone: "Portable",
  phonePlaceholder: "(00) 00000-0000",
  email: "E-mail",
  emailPlaceholder: "exemple@usefixit.com.br",
  evaluation: "Évaluation",
  hasIndication: "A une indication",
  previousUse: "Utilisation préalable d'une orthèse",
  professionalName: "Nom du professionnel ayant réalisé l'évaluation",
  occupation: "Domaine d'activité",
  diagnosis: "Diagnostic",
  limb: "Membre",
  previousOrthesis: "Orthèse utilisée précédemment",
  previousOrthesisPlaceholder: "Ex. : Plâtre, plaque thermoformable, etc...",
  finishingOrder: "Finalisation de la commande",
  measures: "Mesures",
  measuresGuide: "Consulter le guide des mesures",
  filamentColor: "Couleur du filament",
  observations: "Observations",
  observationsPlaceholder:
    "Ex. : Membre œdémateux, mesures du membre controlatéral, etc...",
  colors: {
    aluminum: "Aluminium",
    yellow: "Jaune",
    skyBlue: "Bleu ciel",
    cobaltBlue: "Bleu cobalt",
    darkBlue: "Bleu foncé",
    metalicBlue: "Bleu métallique",
    oceanBlue: "Bleu océan",
    white: "Blanc",
    bronze: "Bronze",
    gray: "Gris",
    golden: "Doré",
    silver: "Argent",
    black: "Noir",
    orange: "Orange",
    oilOrange: "Orange nacré",
    lilac: "Lilas",
    ivory: "Ivoire",
    natural: "Naturel",
    pistachio: "Pistache",
    pink: "Rose",
    purple: "Violet",
    darkPurple: "Violet foncé",
    metalicPurple: "Violet métallique",
    green: "Vert",
    darkGreen: "Vert foncé",
    forestGreen: "Vert forêt",
    tiffanyGreen: "Vert Tiffany",
    red: "Rouge",
  },
  jobs: {
    physiotherapist: {
      value: "physiotherapist",
      optionText: "Kinésithérapeute",
    },
    orthopedist: {
      value: "orthopedist",
      optionText: "Orthopédiste",
    },
    occupationalTherapist: {
      value: "occupationalTherapist",
      optionText: "Ergothérapeute",
    },
    other: {
      value: "other",
      optionText: "Autre",
    },
  },
  sides: {
    left: "Gauche",
    right: "Droite",
    unique: "Unique",
  },
  diagnostic: {
    newDiagnosisLabel: "Vous ne trouvez pas de diagnostic ?",
    diagnosisRegisters: "Enregistrer un",
    newDiagnosisModalLabel: "Enregistrer un Diagnostic",
    name: "Nom",
    icd: "CID",
    register: "Enregistrer",
  },
  gender: {
    gender: "Genre",
    male: { optionText: "Masculin", value: "male" },
    female: { optionText: "Féminin", value: "female" },
  },
};

const evaluation: ITranslateEvaluation = {
  patient: "Patient",
  name: "Nom",
  age: "Âge",
  months: "Mois",
  weight: "Poids",
  height: "Taille",
  evaluation: "Évaluation",
  diagnosis: "Diagnostic",
  previousOrthesis: "Utilisation préalable d'une orthèse",
  indication: "Indication",
  observations: "Observations",
  header: "Évaluation",
  requirements: "Exigences",
  printer: "Imprimante",
  side: "Côté",
  bodyPart: "Partie du corps",
  segmentaion: "Segmentation",
  injuryType: "Type de lésion",
  surgeryDate: "Date de chirurgie",
  urgency: "Urgence",
  urgencyCause: "Justification de l'urgence",
  urgencyNotComprooved: "Urgence non prouvée",
  measure: "Mesure",
  planning: "Planification",
  professional: "Professionnel",
  images: "Images",
  files: "Fichiers",
};

const form_errors: ITranslateForm_Errors = {
  alert: "Vérifiez les champs en erreur.",
  required: "${path} Le champ est obligatoire",
  minRequired: "${path} Obligatoire",
  matchPassword: "${path} Les mots de passe ne correspondent pas",
  validEmail: "${path} Entrez une adresse e-mail valide",
  validCountry: "${path} Sélectionnez un pays",
  invalidValue: "${path} Entrez une valeur valide",
  passwordLength:
    "${path} Le mot de passe doit comporter au moins 8 caractères",
  passwordCharacters:
    "${path} Le mot de passe doit contenir au moins une lettre majuscule, un chiffre et un caractère spécial",
};

const disabled_user: ITranslateDisabled_user = {
  principal: "Votre accès est désactivé en raison de problèmes en suspens",
  secundary: "Veuillez contacter le SAF",
  disabledFixiter: "Oops, votre abonnement a expiré !",
  disabledFixiterSecondary: "Contactez le SAF pour le renouveler",
};

const financial: ITranslateFinancial = {
  prices: {
    header: "Prix",
    create: "Créer un prix",
    edit: "Modifier le prix",
    update: "Mettre à jour",
    save: "Enregistrer",
    country: {
      default: "TOUS",
    },
    confirm: {
      deactivate: "Êtes-vous sûr de vouloir désactiver ce prix ?",
      activate: "Êtes-vous sûr de vouloir activer ce prix ?",
    },
    field: {
      country: "Pays",
      order_type: "Type de commande",
      solution_type: "Type de solution",
      currency: "Devise",
      file_price: "Prix",
      solution: "Solution",
      plan_name: "Nom du plan",
      trial_period: "Période d'essai",
      numberOfSolutions: "Nbre de solutions",
      active: "Actif",
      inactive: "Inactif",
    },
    solution_type: {
      standard: "Standardisé",
      custom: "Personnalisé",
      plan: "Plan chirurgical",
      default: "Tous",
    },
    order_type: {
      is_patient: "B2C",
      is_partner: "B2B",
      is_fixiter: "FIXITER",
    },
    plan_type: {
      demo: "Démo",
      advanced: "Avancé",
      basic: "Basique",
      old_signatures: "Anciens modèles",
    },
  },
  plans: {
    confirm: {
      deactivate: "Êtes-vous sûr de vouloir désactiver ce plan ?",
      activate: "Êtes-vous sûr de vouloir activer ce plan ?",
    },
    financial: {
      free: "Êtes-vous sûr de vouloir marquer le plan comme gratuit ?",
      paid: "Êtes-vous sûr de vouloir marquer le plan comme payant ?",
    },
  },
  situation: {
    paid: "Payé",
    not_paid: "Facturé",
    courtesy: "Courtoisie",
  },
  error: {
    get_prices: "Une erreur s'est produite lors du chargement des prix !",
    get_signature:
      "Vous n'avez pas encore d'abonnement, veuillez contacter le SAF !",
  },
};

const error: ITranslateErrorMessages = {
  printings: {
    completed: "Impression déjà réalisée",
    quantity: "Limite d'impressions atteinte",
    missingpart: "Partie manquante de l'impression, veuillez contacter le SAF",
  },
};

const printer: ITranslatePrinter = {
  setup: "Cliquez pour configurer",
  ready: "Prêt à imprimer",
  erase: "Effacer",
  filament: {
    success: "Configuration du filament effectuée avec succès !",
    error: "Impossible de configurer le filament !",
    filamentConfigs: "Configuration du filament",
    filamentDescription:
      "Veuillez saisir vos données d'achat de filament afin que nous puissions calculer correctement votre coût de matériau.",
    buyingCoin: "Devise d'achat",
    buyingCost: "Coût d'achat du filament / kg",
    configure:
      "Pour afficher les données de coût, veuillez configurer vos données de filament.",
  },
};

const courses: ITranslateCourses = {
  names: {
    fixit: "Technologie Fix it",
    punhofix: "Formation à l'application PunhoFix",
  },
  descriptions: {
    fixit: "En savoir plus sur Fix it et l'impression 3D !",
    punhofix: "Apprenez comment appliquer l'une de nos principales solutions !",
  },
};

const address: ITranslateAddress = {
  address_options: {
    address: "Adresse",
    edit: "Modifier l'adresse",
    delete: "Supprimer l'adresse",
    new_address: "Ajouter une adresse",
    create_as: "Enregistrer en tant que :",
    created_as: "Enregistré en tant que :",
    save: "Enregistrer",
    att: "Mettre à jour",
    close: "Fermer",
    cancel: "Annuler",
  },
  my_addresses: "Mes Adresses",
  billing_address: "ADRESSE DE FACTURATION",
  deliver_address: "ADRESSE DE LIVRAISON",
  company_address: "ADRESSE DE ENTERPRISE",
  address: "Adresse",
  cep: "Code postal",
  number: "Numéro",
  complement: "Complément",
  district: "Quartier",
  city: "Ville",
  state: "État",
};

const person: ITranslatePerson = {
  manager: "Gestionnaire",
  user: "Utilisateur",
  edit_personal_data: "Modifier les données personnelles",
  delete_user: "Supprimer les données personnelles",
  name: "Nom",
  cpf: "CPF",
  cellphone: "Téléphone portable",
  expertise: "Fonction",
  city: "Ville",
  state: "État",
  country: "Pays",
  create_person: "Suivant",
  personal_data: "Données Personnelles",
  save: "Enregistrer",
  error: {
    user_registration_error: "Impossible d'inscrire l'utilisateur ! Veuillez contacter le SAF.",
    delete_user: "Cet utilisateur ne peut pas être supprimé !",
  },
  success: {
    att_user_data: "Données personnelles mises à jour avec succès !",
    user_deleted_success: "Utilisateur supprimé !",
    user_deleted_error: "Impossible de supprimer l'utilisateur !",
  },
  bpRegisterPopover: "À utiliser uniquement par les utilisateurs internes de BP",
  bpRegister: "Inscription BP"
};

const coins: ITranslateCoins = {
  paraguai: {
    coin: "Guarani",
    country_name: "Paraguay",
  },
  mozambique: {
    coin: "Metical",
    country_name: "Mozambique",
  },
  uruguai: {
    coin: "Peso uruguayen",
    country_name: "Uruguay",
  },
  swiss: {
    coin: "Franc suisse",
    country_name: "Suisse",
  },
  europe: {
    coin: "Euro",
    country_name: "Europe",
  },
  argentina: {
    coin: "Peso argentin",
    country_name: "Argentine",
  },
  el_salvador: {
    coin: "Dollar",
    country_name: "El Salvador",
  },
  venezuela: {
    coin: "Bolivar vénézuélien",
    country_name: "Venezuela",
  },
  brazil: {
    coin: "Réal",
    country_name: "Brésil",
  },
  moneySymbol: "R$",
};

const fr: ILanguageMessages = {
  translations: {
    transactions,
    language,
    noInfo,
    userProfile,
    userRegistration,
    login,
    tips,
    creators,
    global,
    dashboard,
    solutions,
    success,
    orders,
    profile,
    sidebar,
    navMenu,
    topbar,
    dropZone,
    fileList,
    goBackBtn,
    optionsBtn,
    orderBox,
    searchBar,
    surgicalPlanning,
    standardSolution,
    customSolution,
    evaluation,
    form_errors,
    disabled_user,
    financial,
    fixiterOnboarding,
    error,
    printer,
    courses,
    address,
    person,
    coins,
    bills,
  },
};

const messages = { fr };

export { messages };
